import React, { Component } from 'react';
import YouArePharmacienPopup from '../../helpers/YouArePharmacienPopup/YouArePharmacienPopup';
import PharmacieCard from '../../helpers/PharmacieCard/PharmacieCard';
import pharmaciesServices from '../../services/pharmacies.services';
import patientsService from '../../services/patients.service';
import SearchSelect from '../../helpers/AsyncSelect';
import CookieConsent from "react-cookie-consent";
import Header from '../../helpers/header/header';
import { LANDING_PAGE_PHARMACIST } from '../../config';
import { Button, Input } from 'reactstrap';
import { Helmet } from 'react-helmet';
import './Accueil.css';

class Accueil extends Component {
    constructor(props) {
        super(props);
        this.state = {
            nomDeParmacie: '',
            lieuxDeParmacie: '',
            Pharmacies: [],
            currentPage: 1,
            name: "",
            searched: null,
            cityId: null,
            sectorId: null,
            limit: 10,
            selectedCity: null,
            searchedPharmacie: false,
            nomDeParmacie: "",
            youArePharmacienPopup: false,
            cookiePopup: true,
            isLoged: null,
            country: null
        };
        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {
        this.getCurrentPatient()
        this.getAllPharmacies()
    }

    getCurrentPatient = async () => {
        try {
            let response = await patientsService.getCurrentPatient();
            this.setState({ country: response.data?.patientData?.country, isLoged: response.data })
        } catch (error) {
            this.getPatientLocalisation()
            console.log(error);
        }
    }

    getPatientLocalisation = async () => {
        try {
            let response = await patientsService.getPatientLocalisation()
            this.setState({ country: response.data?.countryCode ?? "ma", loader: false })
        } catch (error) {
            console.log(error);
        }
    }

    handleChange = (event) => {
        this.setState({ nomDeParmacie: event.target.value });
    }

    getAllPharmacies = async () => {
        try {
            let data = {
                name: "",
                cityId: "",
                sectorId: ""
            }
            let response = await pharmaciesServices.getAllPharmacies(1, 5, data);
            this.setState({ Pharmacies: response.data.pharmacies, searchedPharmacie: true, totalItems: response.data.total });
        } catch (error) {
            console.error(error);
        }
    }

    render() {
        const { nomDeParmacie, selectedCity, searched, country } = this.state
        return (
            <div className='accueill_container'>
                <Helmet>
                    <meta charSet="utf-8" />
                    <meta name="viewport" content="width=device-width, initial-scale=1" />
                    <title>MonPharmacien - 1er annuaire interactif de pharmacies au Maroc</title>
                    <meta name="description" content="1er annuaire interactif de pharmacies au Maroc. Il permet aux pharmaciens de mettre en ligne leurs tours de garde et aux patients de trouver la pharmacie la plus proche et de communiquer avec leurs pharmaciens." />
                    <meta name="theme-color" content="#00c389" />
                    <meta name="keywords" content="Pharmacie de garde,Pharmacie,Officine" />
                </Helmet>
                <div className="Accueil">
                    <Header />
                    <div className="Accueil_contect">
                        <p className="Accueil_paragraphe">Trouver la pharmacie<br /> la plus proche</p>
                        <Input type="text" id="exampleEmail" placeholder="Nom de la pharmacie" className="search_input border_inside" value={this.state.nomDeParmacie} onChange={this.handleChange} />
                        <SearchSelect
                            fetchedUrl='/cities/deep-search'
                            HTTPMethod="getall"
                            receivedObject="cities"
                            optionLabelProperty="name"
                            optionValueProperty="id"
                            noOptoins="Saisissez au moins un caractère"
                            value={this.state.selectedCity}
                            country={country}
                            placeholder="Où ? (Ville, Secteur, Code postal...)"
                            onChange={(e) => {
                                if (e) {
                                    this.setState({ selectedCity: [], cities: [] }, () => {
                                        let city = {
                                            id: e.value
                                        }
                                        this.state.cityId = city;
                                        this.setState({
                                            searched: e.value,
                                            selectedCity: [...this.state.selectedCity, e]
                                        })
                                    })
                                }
                            }}
                        />
                        <Button className="input_button" onClick={() => this.props.history.push({
                            pathname: `/pharmacies`,
                            state: {
                                selectedCity: selectedCity,
                                cityId: searched ? searched.city && searched.city.id : "",
                                sectorId: searched ? searched.sector && searched.sector.id : "",
                                pharmacieName: nomDeParmacie
                            }
                        })} >Rechercher</Button>
                    </div>
                </div>
                <div className="card_slider">
                    <div>
                        <p className="lastPharma_paragraphe">Les dernières pharmacies inscrites</p>
                    </div>
                    <div className="Accueil_pharmacieCard">
                        {this.state.Pharmacies.map((item, i) => {
                            return <PharmacieCard item={item} key={i} />
                        })}
                    </div>
                </div>
                {!this.state.isLoged &&<div className="who_are_we">
                    <p className="Accueil_paragraphe_green" onClick={() => this.setState({ youArePharmacienPopup: !this.state.youArePharmacienPopup })}>Vous êtes un pharmacien d'officine ?</p>
                    <p className="Accueil_paragraphe_buttom"> L’objectif de MonPharmacien est d’améliorer la relation pharmacien-patient,
                    pour un meilleur accès aux soins et un meilleur suivi des patients auprès de leur pharmacie d’officine.
                    <br />
                        <br />Inscrivez-vous gratuitement dès aujourd’hui pour vous rapprocher de vos patients. </p>
                     <Button className="input_button" onClick={() => window.open(LANDING_PAGE_PHARMACIST)} style={{ fontSize: "14px" }}>Inscrivez-vous dès maintenant</Button>
                    {this.state.youArePharmacienPopup && <YouArePharmacienPopup youArePharmacienPopup={this.state.youArePharmacienPopup} />}
                </div>}
            </div>
        );
    }
}

export default Accueil;
