import React, { Component } from 'react';
import './Contact.css';
import Header from '../../helpers/header/header';
import { Button, Input, Label } from 'reactstrap';
import { Helmet } from 'react-helmet';
import citiesService from '../../services/cities.service';
import patientsService from '../../services/patients.service';

class Contact extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fields: {
                ...this.initialFields,
            },
            errors: {},
            isVevified: false,
            authonticatedUser: null,
            emailSnt: false,
            cities: [],
            successMessage: null,
            conditionsCheck: false,
            conditionsCheckError: false,
            img: null
        };
        this.handleChange = this.handleChange.bind(this)
    }


    initialFields = {
        fullName: '',
        email: '',
        mobileNumber: '',
        city: '',
        message: '',
    }

    componentDidMount() {
        document.title = "MonPharmacien - 1er annuaire interactif de pharmacies au Maroc"
        this.getCurrentPatient()
        this.getAllcities()
        this.creatImage()
    }

    getCurrentPatient = async () => {
        try {
            let response = await patientsService.getCurrentPatient();
            this.setState({ authonticatedUser: response.data })
        } catch (error) {
            console.log(error);
        }
    }

    handleChange = (input) => (e) => {
        let fieldsCopy = JSON.parse(JSON.stringify(this.state.fields));
        fieldsCopy[input] = e.target.value;
        this.setState({ fields: fieldsCopy });
    };

    handleValidation = async () => {
        const { fields, conditionsCheck } = this.state;
        let errors = {};
        let conditionsCheckError = null;
        let formIsValid = true;

        if (fields["fullName"] === "") {
            formIsValid = false;
            errors["fullName"] = "Ce champ est requis";
        }

        if (fields["message"] === "") {
            formIsValid = false;
            errors["message"] = "Ce champ est requis";
        }

        if (fields["mobileNumber"] === "") {
            formIsValid = false;
            errors["mobileNumber"] = "Ce champ est requis";
        }

        if (fields["mobileNumber"].length < 10 && fields["mobileNumber"].length > 0) {
            formIsValid = false;
            errors["mobileNumber"] = "Minimum 10 caractères";
        }

        if (fields["mobileNumber"] && fields["mobileNumber"] !== "") {
            if (
                !fields["mobileNumber"].match(
                    /((?:\+|00)[17](?: |-)?|(?:\+|00)[1-9]\d{0,2}(?: |-)?|(?:\+|00)1-\d{3}(?: |-)?)?(0\d|\([0-9]{3}\)|[1-9]{0,3})(?:((?: |-)[0-9]{2}){4}|((?:[0-9]{2}){4})|((?: |-)[0-9]{3}(?: |-)[0-9]{4})|([0-9]{7}))/
                )
            ) {
                formIsValid = false;
                errors["mobileNumber"] = "Numéro invalide";
            }
        }

        if (fields["email"] === "") {
            formIsValid = false;
            errors["email"] = "Ce champ est requis";
        }

        if (fields["email"] !== "") {
            let lastAtPos = fields["email"].lastIndexOf("@");
            let lastDotPos = fields["email"].lastIndexOf(".");

            if (
                !(
                    lastAtPos < lastDotPos && lastAtPos > 0 && fields["email"].indexOf("@@") === -1 && lastDotPos > 2 && fields["email"].length - lastDotPos > 2
                )
            ) {
                formIsValid = false;
                errors["email"] = "e-mail n'est pas valide";
            }
        }

        this.setState({ errors: { ...this.state.errors, ...errors, } });
        return formIsValid;
    };

    contactUs = async () => {
        this.setState({ errors: {}}, async () => {
            let valid = await this.handleValidation();
            if (valid) {
                try {
                    await citiesService.contactUs(this.state.fields);
                    // const fieldsCopy = this.state.fields
                    const fieldsCopy = {
                        ... this.state.fields,
                        fullName: '',
                        email: '',
                        mobileNumber: '',
                        city: '',
                        message: '',
                    }
                    this.setState({ fields: fieldsCopy, emailSnt: true }, () =>
                        this.showSuccess('Votre message a été bien envoyé'))
                } catch (error) {
                    console.error(error);
                }
            }
        });
    }

    getAllcities = async () => {
        try {
            let response = await citiesService.getAllCities();
            this.setState({ cities: response.data.cities })
        } catch (error) {
            console.log(error);
        }
    }

    showSuccess(message) {
        this.setState({ successMessage: message }, () => window.scrollTo(0, 0));
        setTimeout(() => {
            this.setState({ successMessage: "" });
        }, 3000);
    }

    creatImage = () => {
        let canvasTxt = document.getElementById("canvasComponent").getContext("2d");
        canvasTxt.canvas.width = canvasTxt.measureText('support@sobrus.com').width + 30;
        canvasTxt.canvas.height = 22;
        canvasTxt.canvas.fillStyle = "#ffffff";
        canvasTxt.fillStyle = "#ffffff";
        canvasTxt.canvas.font = "12px openSans";
        canvasTxt.font = "12px openSans";
        canvasTxt.fillText("support@sobrus.com", 0, 20);
        this.setState({ img: canvasTxt.canvas.toDataURL() });
    }

    render() {
        const { authonticatedUser, fields, errors, cities } = this.state
        return (
            <div className="Contact" >
                {this.state.successMessage ? <p className="successMessage">{this.state.successMessage}</p> : ""}
                <Helmet>
                    <meta charSet="utf-8" />
                    <meta name="viewport" content="width=device-width, initial-scale=1" />
                    <title>MonPharmacien - 1er annuaire interactif de pharmacies au Maroc</title>
                    <meta name="description" content="1er annuaire interactif de pharmacies au Maroc. Il permet aux pharmaciens de mettre en ligne leurs tours de garde et aux patients de trouver la pharmacie la plus proche et de communiquer avec leurs pharmaciens." />
                    <meta name="theme-color" content="#00c389" />
                    <meta name="keywords" content="Pharmacie de garde,Pharmacie,Officine" />
                </Helmet>
                <Header />
                <div className="contact_form">
                    <p className="Contact_title">Contactez-nous</p>
                    <p className="Contact_paragraphe">On est toujours à votre écoute n'hésitez pas à nous contacter en remplissant le formulaire ci-dessous.</p>
                </div>

                <div className="contact_form">
                    <Input type="text" id="exampleEmail" placeholder="Entrez votre nom *" className="search_input border_inside" onChange={this.handleChange("fullName")} value={fields["fullName"]} />
                    {errors["fullName"] ? <p className="erreurInputText"><><i className="far fa-times-circle"></i> {errors["fullName"]}</> </p> : ""}
                    <Input type="text" id="exampleEmail" placeholder="Entrez votre E-mail * " className="search_input border_inside" onChange={this.handleChange("email")} value={fields["email"]} />
                    {errors["email"] ? <p className="erreurInputText"><><i className="far fa-times-circle"></i> {errors["email"]}</> </p> : ""}
                    <Input type="text" id="exampleEmail" placeholder="Entrez votre téléphone * " className="search_input border_inside" onChange={this.handleChange("mobileNumber")} value={fields["mobileNumber"]} />
                    {errors["mobileNumber"] ? <p className="erreurInputText"><><i className="far fa-times-circle"></i> {errors["mobileNumber"]}</> </p> : ""}
                    <Input type="select" name="select" placeholder="Entrez votre ville" id="exampleSelect" className="search_input border_inside select_option" onChange={this.handleChange("city")} >
                        <option >Entrez votre ville</option>
                        {
                            cities && cities.map((item, i) => {
                                return (<option value={item.id} >{item.name}</option>)
                            })
                        }
                    </Input>
                    <Input type="textarea" name="text" placeholder="Entrez votre message * " className="search_input message_input_textarea border_inside" id="exampleText" onChange={this.handleChange("message")} value={fields["message"]} />
                    {errors["message"] ? <p className="erreurInputText"><><i className="far fa-times-circle"></i> {errors["message"]}</> </p> : ""}
                    <div className="PhotoDeProfileUploadPopup_ckeckbox_container">
                        <p className="PhotoDeProfileUploadPopup_ckeckbox_title">* Champs obligatoires </p>
                        <p className="PhotoDeProfileUploadPopup_ckeckbox_title">Par le biais de ce formulaire, Sobrus collecte vos données personnelles en vue de répondre à votre demande suite à l’envoi du formulaire de contact.
                        Ce traitement a fait l’objet d’une déclaration de la CNDP sous le numéro D-GC-252/2018.
Vous pouvez vous adresser à Sobrus par e-mail à l’adresse suivante : <a><canvas id="canvasComponent" style={{ display: "none" }} /><img src={this.state.img} /></a> pour exercer vos droits d’accès, de rectification et d’opposition conformément aux dispositions de la loi 09-08.</p>
                    </div>
                </div>

                <div className="contact_form_button contact_form">
                    <Button className="input_button" onClick={() => this.contactUs()}>Envoyez</Button>
                </div>
            </div>
        );
    }
}

export default Contact;