import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { Button } from 'reactstrap';
import { LOGIN_PATIENT, MANAGE_PROFILE_URL } from '../../config';
import Header from '../../helpers/header/header';
import patientsService from '../../services/patients.service';
import BeatLoader from "react-spinners/BeatLoader";
import './PersonnelInfo.css'

class PersonnelInfo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userInfo: null,
            loading: true
        };
    }

    componentDidMount() {
        this.getCurrentPatient()
    }

    getCurrentPatient = async () => {
        try {
            let response = await patientsService.getCurrentPatient();
            this.setState({ userInfo: response.data, loading: false })
        } catch (error) {
            if (error.response?.status === 401) {
                window.location.href = LOGIN_PATIENT
            }
            console.log(error);
        }
    }

    render() {
        const { userInfo, loading } = this.state
        return (
            <>{loading && <div className="loader_background">
                 <BeatLoader color={"#00c389"} loading={loading} size={20} />
            </div>}
                <Helmet>
                    <meta charSet="utf-8" />
                    <meta name="viewport" content="width=device-width, initial-scale=1" />
                    <title>MonPharmacien - 1er annuaire interactif de pharmacies au Maroc</title>
                    <meta name="description" content="1er annuaire interactif de pharmacies au Maroc. Il permet aux pharmaciens de mettre en ligne leurs tours de garde et aux patients de trouver la pharmacie la plus proche et de communiquer avec leurs pharmaciens." />
                    <meta name="theme-color" content="#00c389" />
                    <meta name="keywords" content="Pharmacie de garde,Pharmacie,Officine" />
                </Helmet>
                <div className="PersonnelInfo">
                    <Header />
                    <div className="PersonnelInfo_form">
                        <p className="PersonnelInfo_title">Infomations personnelles</p>
                        <p className="PersonnelInfo_paragraphe">Vous pouvez modifier vos informations personnelles à tout moment et en toute simplicité</p>
                    </div>
                </div>
                <div className="Info_data_container">
                    <div className="Info_data">
                        <div className="generale_info_from_account">
                            <p className="generale_info_from_account_title">Informations générales</p>
                            <p className="Info_data_title">Nom</p>
                            <p className="Info_data_paragraphe">{userInfo && userInfo.lastName}</p>
                            <p className="Info_data_title">Prénom</p>
                            <p className="Info_data_paragraphe">{userInfo && userInfo.firstName}</p>
                        </div>
                        <div className="generale_info_from_user">
                            <p className="generale_info_from_account_title">Adresse</p>
                            <p className="Info_data_title">Pays</p>
                            <p className="Info_data_paragraphe">{userInfo && userInfo?.patientData?.country === "dj" ? "Djibouti" :
                                userInfo?.patientData?.country === "ma" ? "Maroc" :
                                    userInfo?.patientData?.country === "sn" ? "Sénégal" : ""}</p>
                            <p className="Info_data_title">Ville</p>
                            <p className="Info_data_paragraphe">{userInfo && userInfo?.patientData?.city ? userInfo?.patientData?.city?.name : ""}</p>
                            {userInfo && userInfo?.patientData?.country === "ma" && <>
                                <p className="Info_data_title">Secteur</p>
                                <p className="Info_data_paragraphe">{userInfo && userInfo?.patientData?.city ? userInfo?.patientData?.sector?.name : ""}</p>
                            </>}
                            {userInfo && userInfo?.patientData?.country !== "ma" && <>
                                <p className="Info_data_title">Code postal</p>
                                <p className="Info_data_paragraphe">{userInfo && userInfo?.patientData?.city ? userInfo?.patientData?.zipcode : ""}</p>
                            </>}
                        </div>
                        <Button className="input_button" onClick={() => this.props.history.push('/profile/edit')}>Modifier</Button>
                    </div>
                    <a className="Info_data_container_privecy" onClick={() => this.props.history.push('/privacy_policy')}>Politique de confidentialité</a>
                </div>
                <div className="Info_data_form_button">
                    <Button className="update_data_input_button input_button" onClick={() => window.open(MANAGE_PROFILE_URL)}>Gérer mon compte Sobrus</Button>
                </div>
            </>

        );
    }
}

export default PersonnelInfo;