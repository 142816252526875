import React, { Component } from 'react';
import { AiOutlineClose } from 'react-icons/ai';
import { withRouter } from 'react-router-dom';
import { Button } from 'reactstrap';
import { SIGNUP_PATIENT } from '../../config';

class YouArePharmacienPopup extends Component {
    constructor(props) {
        super(props);
        this.state = {
            collapsed: true,
        };
        this.toggleNavbar = this.toggleNavbar.bind(this);
    }

    componentDidMount() {
        const collapsed = this.props && this.props.youArePharmacienPopup
        this.setState({ collapsed })
    }

    toggleNavbar = () => {
        this.setState({
            collapsed: !this.state.collapsed,
        });
    }

    render() {
        return (
            <div className={this.state.collapsed ? "BottomPopup" : "BottomPopup_closed"} >
                <div className="BottomPopup-wrapper" onClick={this.toggleNavbar} style={{ backgroundColor: 'transparent', height: "60%" }}></div>
                <div className="BottomPopup-nav">
                    <div className='BottomPopup_contact' >
                        <div className="PersonnelInfo_form" >
                            <p className="Accueil_paragraphe_green" style={{ textAlign: 'start' }}>Vous êtes un pharmacien d'officine ?</p>
                            <p className="BottomPopup_paragraphe" >L'objectif de MonPharmacien est d'améliorer la relation pharmacien-patient, pour un meilleur accès aux soins et un meilleur suivi des patients auprès de leur pharmacie d'officine.
Inscrivez-vous gratuitement dès aujourd'hui pour vous rapprocher de vos patients.</p>
                        </div>
                        <AiOutlineClose className="BottomPopup_AiOutlineClose" onClick={this.toggleNavbar} />
                    </div>
                    <div>
                        <Button className="input_button" onClick={() => window.open(SIGNUP_PATIENT)}>Inscrivez-vous dès maintenant</Button>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(YouArePharmacienPopup);