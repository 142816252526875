import React, { Component } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import PharmacyList from './pages/PharmacyList/PharmacyList';
import Accueil from './pages/Accueil/Accueil';
import Contact from './pages/Contact/Contact';
import FavoritPharmacie from './pages/FavoritPharmacie/FavoritPharmacie';
import PersonnelInfo from './pages/PersonnelInfo/PersonnelInfo';
import ProfileEdit from './pages/ProfileEdit/ProfileEdit';
import PrivacyPolicy from './pages/PrivacyPolicy/PrivacyPolicy';
import ConsultedPharmacie from './pages/ConsultedPharmacie/ConsultedPharmacie';
import PharmacyProfileVide from './pages/PharmacyProfileVide/PharmacyProfileVide';
import CompleteRegistration from './pages/CompleteRegistration/CompleteRegistration';
import SuccessComponent from './helpers/AuthComponent/SuccessComponent';
import failureComponent from './helpers/AuthComponent/failureComponent';
import CookieConsent from 'react-cookie-consent';
import { isMobile } from 'react-device-detect';
import Error from './pages/Error/Error';
import './all_css/all_css.css';
import './App.css';

class App extends Component {

  render() {
    if (isMobile) {
      return (
        <>
          <BrowserRouter>
            <div className="App">
              <Switch>
                <Route path='/' exact component={Accueil} />
                <Route path='/contact' exact component={Contact} />

                <Route path='/profile' exact component={PersonnelInfo} />
                <Route path='/profile/edit' exact component={ProfileEdit} />

                <Route path='/pharmacies' exact component={PharmacyList} />
                <Route path='/pharmacie/:slug' exact component={PharmacyProfileVide} />

                <Route path='/favoritePharmacie' exact component={FavoritPharmacie} />
                <Route path='/consultedPharmacie' exact component={ConsultedPharmacie} />
                <Route path='/privacy_policy' exact component={PrivacyPolicy} />

                <Route path='/complete_registration' exact component={CompleteRegistration} />
                <Route path='/auth/success' exact component={SuccessComponent} />
                <Route path='/auth/failed' exact component={failureComponent} />
                <Route component={Error} />
              </Switch>
            </div>
          </BrowserRouter>
          
          <CookieConsent
            location="bottom"
            cookieName="CookieConsent"
            buttonText="Accepter tous les cookies"
            declineButtonText="Refuser tous les cookies"
            enableDeclineButton={true}
            flipButtons={true}
            style={{
              background: "#52747D",
              borderRadius: "8px 8px 0px 0px",
              paddingBottom: "16px",
              display: "block",
              flexDirection: "column",
              textAlign: "start",
              boxSadow: "3px 0px 10px #A9BABF",
            }}
            buttonStyle={{
              color: "#ffffff",
              fontSize: "14px",
              fontFamily: "openSans-Light",
              backgroundColor: "#f6ac32",
              padding: "10px 7px",
              borderRadius: "6px",
              margin: "5px",
              marginLeft: "15px"
            }}
            declineButtonStyle={{
              color: "#f6ac32",
              fontSize: "14px",
              fontFamily: "openSans-Light",
              border: "1px solid #f6ac32",
              backgroundColor: "transparent",
              padding: "10px 7px",
              borderRadius: "6px",
              margin: "5px"
            }}
            expires={150}
          >
            <p className="cookiePopup_title">Ce site utilise des cookies 🍪</p>
            <p className="cookiePopup_paragraph" style={{ marginBottom: '0px' }}>Sobrus utilise des cookies afin d’optimiser votre expérience lors de votre navigation.</p>
            <p className="cookiePopup_paragraph">Ces cookies  sont utilisés à des fins de bon fonctionnement du site, de sécurité, de mesure d’audience, d’analyse, d’amélioration de l’expérience utilisateur ainsi qu’à des fins publicitaires. Votre consentement à l’installation de cookies non strictement nécessaires est libre et peut être retiré à tout moment. Vous pouvez donner ou retirer votre consentement globalement ou paramétrer vos préférences par finalité de cookies. </p>
          </CookieConsent>
        </>
      );
    } else {
      const url = window.location.href;
      let newUrl = url.replace('https://m.', 'https://')
  
      return window.location.href = newUrl;
    }
  }
}

export default App;
