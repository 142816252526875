import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { AiFillStar } from 'react-icons/ai';
import { IoMdAddCircleOutline } from 'react-icons/io';
import Header from '../../helpers/header/header';
import favoritePharmaciesService from '../../services/favoritePharmacies.service';
import patientsService from '../../services/patients.service';
import empty_array from '../../Image/empty_array.svg';
import BeatLoader from 'react-spinners/BeatLoader'
import './ConsultedPharmacie.css';
import { LOGIN_PATIENT } from '../../config';

class ConsultedPharmacie extends Component {
    constructor(props) {
        super(props)
        this.state = {
            consultedPharmacies: [],
            currentPage: 1,
            limit: 5,
            totalItems: null,
            flashMessage: false,
            loading: true
        }
    }

    componentDidMount() {
        this.getConsultedPharmacies()
    }

    getConsultedPharmacies = async () => {
        const { currentPage, limit } = this.state
        try {
            let response = await patientsService.getConsultedPharmacies(currentPage, limit);
            this.setState({ consultedPharmacies: response.data.pharmacies, totalItems: response.data.total, loading: false })
        } catch (error) {
            if (error.response?.status === 401) {
                window.location.href = LOGIN_PATIENT
            }
            console.log(error);
        }
    }

    toggleFavoritePharmacies = async (id) => {
        try {
            await favoritePharmaciesService.toggleFavoritePharmacies(id)
            this.setState({ FavoritePharmacies: true }, () => this.getConsultedPharmacies())
        } catch (error) {
            if (400 === error?.response?.status) {
                if (error.response.data.message === "you have already favored 3 pharmacies") {
                    window.scrollTo(0, 0);
                    this.setState({ flashMessage: true })
                    this.emailNotefitacion()
                }
            }
        }
    }

    emailNotefitacion() {
        setTimeout(() => {
            this.setState({ flashMessage: false })
        }, 2000);
    }

    render() {
        const { consultedPharmacies, flashMessage, loading } = this.state
        return (
            <> {loading && <div className="loader_background">
                <BeatLoader color={"#00c389"} loading={loading} size={20} />
            </div>}
                <div className="ConsultedPharmacie">
                    <Helmet>
                        <meta charSet="utf-8" />
                        <meta name="viewport" content="width=device-width, initial-scale=1" />
                        <title>MonPharmacien - 1er annuaire interactif de pharmacies au Maroc</title>
                        <meta name="description" content="1er annuaire interactif de pharmacies au Maroc. Il permet aux pharmaciens de mettre en ligne leurs tours de garde et aux patients de trouver la pharmacie la plus proche et de communiquer avec leurs pharmaciens." />
                        <meta name="theme-color" content="#00c389" />
                        <meta name="keywords" content="Pharmacie de garde,Pharmacie,Officine" />
                    </Helmet>
                    {
                        flashMessage &&
                        <div style={{ backgroundColor: "#da3025", paddingTop: "10px", paddingBottom: "10px" }}>
                            <p className="ConsultedPharmacie_paragraphe" style={{ width: "100%", textAlign: "center", marginTop: '0px', marginBottom: "0px" }} >Vous avez atteint le nombre maximum(3) des pharmacies favorites</p>
                        </div>
                    }
                    <Header />
                    <div className="ConsultedPharmacie_container">
                        <div>
                            <p className="ConsultedPharmacie_title">Informations consultées</p>
                            <div className="ConsultedPharmacie_form">
                                <p className="ConsultedPharmacie_paragraphe">Pharmacie</p>
                                <p className="ConsultedPharmacie_paragraphe Pharmacie_city">Ville</p>
                            </div>
                        </div>
                        {
                            consultedPharmacies.length !== 0 ? consultedPharmacies.map((item, i) => {
                                return (
                                    <div className="pharmacie_favorit_data_container" key={i}>
                                        <p className="FavoritPharmacie_name" onClick={() => this.props.history.push(`/pharmacie/${item.slug}`)}>{item.name}</p>
                                        <p className="FavoritPharmacie_name" onClick={() => this.props.history.push(`/pharmacie/${item.slug}`)}>{item.city && item.city.name}</p>
                                        {
                                            item.favorite ? <AiFillStar className="FavoritPharmacie_icon" onClick={() => this.toggleFavoritePharmacies(item.slug)} /> : <IoMdAddCircleOutline className="ConsultedPharmacie_icon" onClick={() => this.toggleFavoritePharmacies(item.slug)} />
                                        }
                                    </div>)
                            }) : <div className="empty_array_container">
                                    <img src={empty_array} className="empty_array" />
                                    <p>Vous n'avez aucune pharmacie consultée pour le moment.</p>
                                    <button onClick={() => this.props.history.push('/pharmacies')}>Nouvelle recherche</button>
                                </div>
                        }
                    </div>
                </div>
            </>
        );
    }
}

export default ConsultedPharmacie;