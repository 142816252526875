import axios from "axios";
import { api_url } from "../config";

const authAxios = axios.create({
    baseURL: api_url + "app/pharmacies"
});

class pharmaciesServices {
    constructor() {
        authAxios.interceptors.response.use(
            function (response) {
                return response;
            },
            function (error) {
                return Promise.reject(error);
            }
        );
    }

    getAllPharmacies(page, limit, data) {
        let params = {
            page: page,
            limit: limit
        }
        return authAxios.post(`/get`, data, { params });
    }

    getPharmacieById(id) {
        return authAxios.get(`/${id}`, { withCredentials: true });
    }

    getPharmacieByslug(slug) {
        return authAxios.get(`/${slug}`, { withCredentials: true });
    }
}

export default new pharmaciesServices()