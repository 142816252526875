import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import patientsService from "../../services/patients.service";

class SuccessComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            redirect_uri: "/",
            registration_uri: '/complete_registration',
            is_loaded: false,
            country: null
        };
    }

    componentDidMount() {
        this.getCurrentPatient()
    }

    getCurrentPatient = async () => {
        try {
            let response = await patientsService.getCurrentPatient();
            this.setState({ authonticatedUser: response.data, is_loaded: true, country: response.data?.patientData?.country }, () => {
                if (this.state.is_loaded && this.state.country) {
                    return this.props.history.push(this.state.redirect_uri)
                } else {
                    return this.props.history.push(this.state.registration_uri)
                }
            })
        } catch (error) {
            console.log(error);
        }
    }

    render() {
        return <div></div>
    }
}

export default withRouter(SuccessComponent);
