import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import SearchSelect from '../../helpers/AsyncSelect';
import patientsService from '../../services/patients.service';
import Header from '../../helpers/header/header';
import { Button, Input, Label } from 'reactstrap';
import { LOGIN_PATIENT } from '../../config';

class CompleteRegistration extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isVevified: false,
            authonticatedUser: null,
            emailSnt: false,
            location: null,
            country: "ma",
            selectedCity: null,
            selectedSector: null,
            sectorId: null,
            cityId: null,
            zipcode: null,
            loader: true,
            conditionsCheck: false,
            conditionsCheckError: false
        }
        this.handleChange = this.handleChange.bind(this)
    }

    componentDidMount() {
        document.title = "MonPharmacien - 1er annuaire interactif de pharmacies au Maroc";
        this.getPatientLocalisation()
        this.creatImage()
    }

    handleChange = (e) => {
        this.setState({ country: e.target.value });
    };

    handleZipcodeChange = (e) => {
        this.setState({ zipcode: e.target.value, selectedCity: [] });
    };

    handleValidation = async () => {
        const { conditionsCheck } = this.state;
        let conditionsCheckError = null
        let formIsValid = true;

        if (conditionsCheck === false) {
            formIsValid = false;
            conditionsCheckError = true
        }

        this.setState({ conditionsCheckError });
        return formIsValid;
    };

    updatePatient = async () => {
        this.setState({ conditionsCheckError: false }, async () => {
            let valid = await this.handleValidation();
            console.log('valid', valid);
            if (valid) {
                try {
                    const data = {
                        city: {
                            id: this.state.cityId
                        },
                        sector: {
                            id: this.state.sectorId
                        },
                        country: this.state.country,
                        zipcode: this.state.zipcode
                    }
                    await patientsService.updatePatient(data);
                    this.props.history.push('/')
                } catch (error) {
                    console.error(error);
                }
            }
        });
    }


    getPatientLocalisation = async () => {
        try {
            let response = await patientsService.getPatientLocalisation()
            this.setState({ country: response.data?.countryCode ?? "ma", loader: false })
        } catch (error) {
            if (error.response?.status === 401) {
                window.location.href = LOGIN_PATIENT
            }
            console.log(error);
        }
    }

    handleSkip = async () => {
        try {
            const data = {
                country: this.state.country
            }
            await patientsService.updatePatient(data);
            this.props.history.push('/')
        } catch (error) {
            this.props.history.push('/')
        }
    }

    creatImage = () => {
        let canvasTxt = document.getElementById("canvasComponent").getContext("2d");
        canvasTxt.canvas.width = canvasTxt.measureText('support@sobrus.com').width + 30;
        canvasTxt.canvas.height = 22;
        canvasTxt.canvas.fillStyle = "#ffffff";
        canvasTxt.fillStyle = "#ffffff";
        canvasTxt.canvas.font = "12px openSans";
        canvasTxt.font = "12px openSans";
        canvasTxt.fillText("support@sobrus.com", 0, 20);
        this.setState({ img: canvasTxt.canvas.toDataURL() });
    }

    render() {
        const { country, cityId, zipcode } = this.state
        return (
            <div className="ProfileEdit">
                <Helmet>
                    <meta charSet="utf-8" />
                    <meta name="viewport" content="width=device-width, initial-scale=1" />
                    <title>MonPharmacien - 1er annuaire interactif de pharmacies au Maroc</title>
                    <meta name="description" content="1er annuaire interactif de pharmacies au Maroc. Il permet aux pharmaciens de mettre en ligne leurs tours de garde et aux patients de trouver la pharmacie la plus proche et de communiquer avec leurs pharmaciens." />
                    <meta name="theme-color" content="#00c389" />
                    <meta name="keywords" content="Pharmacie de garde,Pharmacie,Officine" />
                </Helmet>
                <Header />
                <div className="PersonnelInfo_form ProfileEdit_Title">
                    <p className="PersonnelInfo_title">Complétez votre inscription</p>
                    <p className="PersonnelInfo_paragraphe">Vous y êtes presque. Il ne vous reste qu’une dernière étape.</p>
                </div>
                <div className="ProfileEdit_contect">
                    <div className="form-group">
                        <label for='country'>Pays :</label>
                        <select className="search_input_edit border_inside" onChange={this.handleChange} value={country}  >
                            <option value="ma" >Maroc</option>
                            <option value="dj" >Djibouti</option>
                            <option value="sn" >Sénégal</option>
                        </select>
                    </div>
                    <div className="form-group">
                        <SearchSelect
                            fetchedUrl='/cities'
                            HTTPMethod="getcities"
                            receivedObject="cities"
                            optionLabelProperty="name"
                            optionValueProperty="id"
                            country={country}
                            value={this.state.selectedCity}
                            placeholder="Ville:"
                            onChange={(e) => {
                                if (e) {
                                    this.setState({ selectedCity: [], cities: [] }, () => {
                                        let city = {
                                            id: e.value
                                        }
                                        this.state.cityId = city;
                                        this.setState({
                                            cityId: e.value,
                                            selectedCity: [...this.state.selectedCity, e]
                                        })
                                    })
                                }
                            }}
                        />
                    </div>
                    {country === "ma" && <div className="form-group">
                        <SearchSelect
                            fetchedUrl='/sectors'
                            HTTPMethod="getsectors"
                            receivedObject="sectors"
                            optionLabelProperty="name"
                            optionValueProperty="id"
                            value={this.state.selectedSectors}
                            country={country}
                            cityId={cityId}
                            placeholder="Secteur :"
                            onChange={(e) => {
                                if (e) {
                                    this.setState({ selectedSectors: [], sectors: [] }, () => {
                                        let sector = {
                                            id: e.value
                                        }
                                        this.state.sectorId = sector;
                                        this.setState({
                                            sectorId: e.value,
                                            selectedSectors: [...this.state.selectedSectors, e]
                                        })
                                    })
                                }
                            }}
                        />
                    </div>
                    }
                    {country !== "ma" && <div className="form-group">
                        <label for="Nom">Code postal :</label>
                        <Input type="text" id="Nom" className="search_input_edit border_inside form-control" onChange={this.handleZipcodeChange} value={zipcode} />
                    </div>}
                    <div className="PhotoDeProfileUploadPopup_ckeckbox_container">
                        <p className="PhotoDeProfileUploadPopup_ckeckbox_title">* Champs obligatoires </p>
                        <p className="PhotoDeProfileUploadPopup_ckeckbox_title">Par le biais de ce formulaire, Sobrus collecte vos données personnelles en vue de répondre à votre demande suite à l’envoi du formulaire de contact.
                        Ce traitement a fait l’objet d’une déclaration de la CNDP sous le numéro D-GC-252/2018.
Vous pouvez vous adresser à Sobrus par e-mail à l’adresse suivante : <a><canvas id="canvasComponent" style={{ display: "none" }} /><img src={this.state.img} /></a> pour exercer vos droits d’accès, de rectification et d’opposition conformément aux dispositions de la loi 09-08.</p>
                    </div>
                    <div className="PhotoDeProfileUploadPopup_ckeckbox_container">
                        <Label check className="PhotoDeProfileUploadPopup_ckeckbox">
                            <Input type="checkbox" onChange={() => this.setState({ conditionsCheck: !this.state.conditionsCheck })} checked={this.state.conditionsCheck} />
                            <p style={{ margin: "0px" }}>J'ai lu et approuvé <a className='PhotoDeProfileUploadPopup_ckeckbox_link' onClick={() => {
                                window.scrollTo(0, 0)
                                this.props.history.push('/privacy_policy')
                            }}>les conditions générales d'utilisation</a> de MonPharmacien</p>
                        </Label>
                        {this.state.conditionsCheckError && <p className="PhotoDeProfileUploadPopup_ckeckbox_title_error">* Champ obligatoire </p>}
                    </div>
                    <div style={{ paddingBottom: "90%", marginTop: "10%" }}>
                        <Button className="input_button" onClick={() => this.updatePatient()} >Enregistrer</Button>

                    </div>
                </div>
            </div>
        );
    }
}

export default CompleteRegistration;
