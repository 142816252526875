import React, { Component } from 'react';
import './PharmacyList.css';
import '../NoSearch/NoSearch.css';
import Header from '../../helpers/header/header';
import { Button, Input } from 'reactstrap';
import empty_image_pharmacieList from '../../Image/empty_image_pharmacieList.png';
import { BsChevronRight, BsFillPersonFill } from 'react-icons/bs';
import { BiBuildings } from 'react-icons/bi'
import pharmaciesServices from '../../services/pharmacies.services';
import SearchSelect from '../../helpers/AsyncSelect';
import noResult from '../../Image/noResult.png'
import { Helmet } from 'react-helmet';
import { withRouter } from 'react-router-dom';
import BeatLoader from "react-spinners/BeatLoader";
import patientsService from '../../services/patients.service';
import PharmacieSearched from '../../helpers/PharmacieSearched/PharmacieSearched';

class PharmacyList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            nomDeParmacie: '',
            lieuxDeParmacie: '',
            Pharmacies: [],
            currentPage: 1,
            name: "",
            cityId: null,
            sectorId: null,
            limit: 10,
            selectedCity: null,
            searchedPharmacie: false,
            totalItems: null,
            loading: true,
            country: null
        };

        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {
        const pharmacieName = this.props && this.props.location.state?.pharmacieName ? this.props.location.stat?.pharmacieName : "";
        const selectedCity = this.props && this.props.location.state?.selectedCity ? this.props.location.state?.selectedCity : "";
        const searched = selectedCity[0]?.value;
        this.setState({ nomDeParmacie: pharmacieName, selectedCity: selectedCity, searched })
        this.getAllPharmacies(this.props.location.state?.sectorId, this.props.location.state?.cityId, this.props.location.state?.pharmacieName);
        this.getCurrentPatient()
    }

    getCurrentPatient = async () => {
        try {
            let response = await patientsService.getCurrentPatient();
            this.setState({ country: response.data?.patientData?.country, isLoged: response.data })
        } catch (error) {
            this.getPatientLocalisation()
            console.log(error);
        }
    }

    getPatientLocalisation = async () => {
        try {
            let response = await patientsService.getPatientLocalisation()
            this.setState({ country: response.data?.countryCode ?? "ma" })
        } catch (error) {
            console.log(error);
        }
    }

    handleChange = (input) => (event) => {
        this.setState({ [input]: event.target.value });
    }

    getAllPharmacies = async (sectorId, cityId, nomDeParmacie) => {
        const { currentPage, limit } = this.state;
        this.setState({ loading: true })
        try {
            let data = {
                name: nomDeParmacie,
                cityId: cityId,
                sectorId: sectorId
            }
            let response = await pharmaciesServices.getAllPharmacies(currentPage, limit, data);
            this.setState({ Pharmacies: response.data.pharmacies, nomDeParmacie: nomDeParmacie, loading: false });
        } catch (error) {
            console.error(error);
        }
    }

    render() {
        const { loading, nomDeParmacie, selectedCity, Pharmacies, searched, country } = this.state
        return (
            <>
                {loading && <div className="loader_background">
                    <BeatLoader color={"#00c389"} loading={loading} size={20} />
                </div>}
                <Helmet>
                    <meta charSet="utf-8" />
                    <meta name="viewport" content="width=device-width, initial-scale=1" />
                    <title>MonPharmacien - 1er annuaire interactif de pharmacies au Maroc</title>
                    <meta name="description" content="1er annuaire interactif de pharmacies au Maroc. Il permet aux pharmaciens de mettre en ligne leurs tours de garde et aux patients de trouver la pharmacie la plus proche et de communiquer avec leurs pharmaciens." />
                    <meta name="theme-color" content="#00c389" />
                    <meta name="keywords" content=" Pharmacie de garde,Pharmacie,Officine,Pharmacie à “Localisation”, Pharmacie NOM, Pharmacie NOM à Localisation" />
                </Helmet>
                <div className="PharmacyList">
                    <Header />
                    <div className="Accueil_contect">
                        <Input type="text" id="exampleEmail" placeholder="Nom de la pharmacie" className="search_input border_inside" value={this.state.nomDeParmacie} onChange={this.handleChange("nomDeParmacie")} style={!(this.state.nomDeParmacie || selectedCity) ? { marginBottom: '20px' } : {}} />
                        {
                            (this.state.nomDeParmacie || selectedCity) && <>
                                <SearchSelect
                                    className="form-control SearchInputs"
                                    fetchedUrl='/cities/deep-search'
                                    HTTPMethod="getall"
                                    receivedObject="cities"
                                    optionLabelProperty="name"
                                    noOptoins="Saisissez au moins un caractère"
                                    optionValueProperty="id"
                                    value={selectedCity}
                                    country={country}
                                    placeholder="Où ? (Ville, Secteur, Code postal...)"
                                    onChange={(e) => {
                                        if (e) {
                                            this.setState({ selectedCity: [], cities: [] }, () => {
                                                let city = {
                                                    id: e.value
                                                }
                                                this.state.cityId = city;
                                                this.setState({
                                                    searched: e.value,
                                                    selectedCity: [...this.state.selectedCity, e]
                                                })
                                            })
                                        }
                                    }}
                                />
                                <Button className="input_button" onClick={() => this.getAllPharmacies(searched ? searched.sector && searched.sector.id : "", searched ? searched.city && searched.city.id : "", nomDeParmacie)} >Rechercher</Button>
                            </>
                        }

                    </div>
                </div>
                <div className="PharmacyList_container">
                    <div className="PharmacyList_contect">
                        {
                            Pharmacies.length !== 0 ?
                                Pharmacies.map((item, i) => {
                                    return (<PharmacieSearched item={item} />)
                                }) : <div className="NoSearch">
                                    <img src={noResult} className="NoSearch_img" />
                                    <div className="NoSearch_content">
                                        <p className="NoSearch_title">AUCUN <br /> RéSULTAT TROUVé</p>
                                        <p className="NoSearch_paragraphe">Revérifez le nom de la pharmacie recherchée ou cherchez par wille et par secteur</p>
                                        <Button className="input_button" onClick={() => window.scrollTo(0, 0)}>Nouvelle recherche</Button>
                                    </div>
                                </div>
                        }

                    </div>
                </div>
            </>
        );
    }
}

export default withRouter(PharmacyList);
