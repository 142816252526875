import React, { Component } from 'react'
import { AiFillFacebook, AiOutlineClose } from 'react-icons/ai';
import { FcGoogle } from 'react-icons/fc';
import { Button } from 'reactstrap';
import { LANDING_PAGE_PHARMACIST } from '../../config';
import sobrus_icon from '../../Image/sobrus_icon.png';
import './BottomPopup.css';

export default class BottomPopup extends Component {
    constructor(props) {
        super(props);
        this.state = {
            collapsed: true
        };
        this.toggleNavbar = this.toggleNavbar.bind(this);
    }

    toggleNavbar = () => {
        this.setState({
            collapsed: !this.state.collapsed,
        });
    }

    render() {
        return (
            <div className={this.state.collapsed ? "BottomPopup" : "BottomPopup_closed"} >
                <div className="BottomPopup-wrapper" onClick={this.toggleNavbar}></div>
                <div className="BottomPopup-nav">
                    <div className='BottomPopup_contact'>
                        <div className="PersonnelInfo_form">
                            <p className="BottomPopup_title">Veuillez<br /> vous connecter</p>
                            <p className="BottomPopup_paragraphe">cette action nécessite que vous connecté. <br /> Connectez-vous ou inscrivez-vous si vous n'avez pas de compte.</p>
                        </div>
                        {/* <AiOutlineClose className="BottomPopup_AiOutlineClose" onClick={this.toggleNavbar} /> */}
                    </div>
                    <div>
                        <Button className="BottomPopup_input_button" onClick={() => window.open(LANDING_PAGE_PHARMACIST)}>Se connecter</Button>
                        <Button className="input_button" onClick={() => window.open(LANDING_PAGE_PHARMACIST)}>S'inscrire</Button>
                        {/* <div className="Option_border">
                            <span className="BottomPopup_border_start"></span>
                            <p className="BottomPopup_border_paragraphe">Ou</p>
                            <span className="BottomPopup_border_end"></span>
                        </div>
                        <Button className="BottomPopup_Sobrus_button" onClick={() => window.location.href = 'https://api.monpharmacien.sobrus.ovh/api/app/connect/facebook'}><img src={sobrus_icon} className="Sing_in_facebook_icon" /><p>Se connecter avec Sobrus</p></Button>
                        <Button className="Sing_in_facebook_button" onClick={() => window.location.href = 'https://api.monpharmacien.sobrus.ovh/api/app/connect/facebook'}><AiFillFacebook className="Sing_in_facebook_icon" /><p>Se connecter avec facebook</p></Button>
                        <Button className="BottomPopup_google_button" onClick={() => window.location.href = 'https://api.monpharmacien.sobrus.ovh/api/app/connect/google'}><FcGoogle className="Sing_in_facebook_icon" /><p>Se connecter avec google</p></Button> */}
                    </div>
                </div>
            </div>
        )
    }
}
