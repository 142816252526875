import React, { Component } from 'react';
import { AiOutlineClose, AiOutlineEye, AiOutlineLogout, AiOutlineSearch, AiOutlineStar } from 'react-icons/ai';
import monPharmacien_logo_colors from '../../Image/monPharmacien_logo_colors.png'
import { LOGIN_PATIENT, MANAGE_PROFILE_URL, LANDING_PAGE_PHARMACIST, SIGNUP_PATIENT } from '../../config';
import patientsService from '../../services/patients.service';
import Logo from '../../Image/MonPharmacien-Logo.svg';
import profilUser from '../../Image/profileHeadet.png';
import { GiHamburgerMenu } from 'react-icons/gi';
import { TiBusinessCard } from 'react-icons/ti';
import { withRouter } from 'react-router-dom';
import { FaHeadset, FaSignInAlt } from 'react-icons/fa';
import { Button } from 'reactstrap';
import './header.css';

class header extends Component {
    constructor(props) {
        super(props);
        this.state = {
            collapsed: true,
            subCategorycollapsed: -1,
            testingLogic: false,
            isLoged: null
        };
        this.toggleNavbar = this.toggleNavbar.bind(this);
    }

    componentDidMount() {
        this.getCurrentPatient()
    }

    toggleNavbar = () => {
        this.setState({
            collapsed: !this.state.collapsed,
            subCategorycollapsed: -1,
            testingLogic: false
        });
    }

    getCurrentPatient = async () => {
        try {
            let response = await patientsService.getCurrentPatient();
            this.setState({ isLoged: response.data })
        } catch (error) {
            console.log(error);
        }
    }


    logout = async () => {
        await patientsService.logout();
        this.setState({ authonticatedUser: null })
    }

    render() {
        return (
            <div className="header">
                <GiHamburgerMenu className="Hamburger" onClick={this.toggleNavbar} />
                <div className={!this.state.collapsed ? "aside" : "aside_closed"} >
                    <div className="aside-nav">
                        <div >
                            <div className='d-flex justify-content-center align-items-center header_slider'>
                                {
                                    this.state.isLoged ?
                                        <>
                                            <div className="loged_header_info">
                                                <div className="user_img_contaner">
                                                    <img src={profilUser} className="user_img" />
                                                </div>
                                                <div className="logedIn_user_info">
                                                    <p>{this.state.isLoged.firstName + " " + this.state.isLoged.lastName}</p>
                                                    <a onClick={() => window.open(MANAGE_PROFILE_URL)}>Gérer mon compte Sobrus</a>
                                                </div>
                                            </div>
                                            <div className="header_margin_top">
                                                <span className="header_border"></span>
                                                <p className="color_header" onClick={() => this.props.history.push("/pharmacies")}><AiOutlineSearch className="header_border_icon" />Trouver une Pharmacie</p>
                                                <span className="header_border"></span>
                                                <p className="color_header" onClick={() => this.props.history.push("/profile")}><TiBusinessCard className="header_border_icon" />Informations personnelles</p>
                                                <span className="header_border"></span>
                                                <p className="color_header" onClick={() => this.props.history.push("/favoritePharmacie")}><AiOutlineStar className="header_border_icon" /> Pharmacies favorites</p>
                                                <span className="header_border"></span>
                                                <p className="color_header" onClick={() => this.props.history.push("/consultedPharmacie")}><AiOutlineEye className="header_border_icon" /> Pharmacies consultées</p>
                                                <span className="header_border"></span>
                                                <p className="color_header" onClick={() => this.props.history.push("/contact")}><FaHeadset className="header_border_icon" /> Contactez-nous</p>
                                                <span className="header_border"></span>
                                                <p className="color_header logout_header" onClick={() => this.logout()}><AiOutlineLogout className="logout_header_icon" /> Déconnexion</p>
                                                <span className="header_border"></span>
                                            </div>
                                        </> : <>
                                            <div className="header_margin_top">
                                                <Button className="connect_input_button" onClick={() => window.open(LOGIN_PATIENT)}>Se connecter</Button>
                                                <Button className="input_button" onClick={() => window.open(SIGNUP_PATIENT)} style={{ fontSize: "13px" }}>S'inscrire</Button>
                                            </div>
                                            <div className="header_margin_top" style={{ marginLeft: "5px" }}>
                                                <a className="go_to_pro_link_selected">Je suis pharmacien</a>
                                                <div className="go_to_pro_link_container">
                                                    <FaSignInAlt className="go_to_pro_link_icon" />
                                                    <a onClick={() => window.open(LANDING_PAGE_PHARMACIST)} className="go_to_pro_link" >Accéder à l'espace Pro</a>
                                                </div>
                                            </div>
                                            <div className="header_margin_top">
                                                <span className="header_border"></span>
                                                <p className="color_header" onClick={() => this.props.history.push("/pharmacies")}><AiOutlineSearch className="header_border_icon" /> Trouver une pharmacie</p>
                                                <span className="header_border"></span>
                                                <p className="color_header" onClick={() => this.props.history.push("/contact")}><FaHeadset className="header_border_icon" /> Contactez-nous</p>
                                                <span className="header_border"></span>
                                            </div>
                                        </>
                                }

                                <div className="header_margin_top">
                                    <img src={monPharmacien_logo_colors} className="header_logo_monPharmacien" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="aside-wrapper" onClick={this.toggleNavbar}>
                        <AiOutlineClose className="AiOutlineClose" onClick={this.toggleNavbar} />
                    </div>
                    
                <a className="privacy_policy_link" onClick={() => this.props.history.push("/privacy_policy")}>Politique de confidentialité</a>
                </div>
                <img src={Logo} className="Logo_monPharmacien" onClick={() => this.props.history.push('/')} />
            </div>
        );
    }
}

export default withRouter(header);
