import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { AiFillStar } from 'react-icons/ai';
import Header from '../../helpers/header/header';
import favoritePharmaciesService from '../../services/favoritePharmacies.service';
import empty_array from '../../Image/empty_array.svg';
import BeatLoader from 'react-spinners/BeatLoader';
import './FavoritPharmacie.css';
import { LOGIN_PATIENT } from '../../config';

class FavoritPharmacie extends Component {
    constructor(props) {
        super(props)
        this.state = {
            favoritePharmacies: [],
            currentPage: 1,
            limit: 20,
            totalItems: null,
            loading: true
        }
    }

    componentDidMount() {
        this.getFavoritePharmacies()
    }

    getFavoritePharmacies = async () => {
        const { currentPage, limit } = this.state
        try {
            console.log('ive been called !!');
            let response = await favoritePharmaciesService.getFavoritePharmacies(currentPage, limit);
            this.setState({ favoritePharmacies: response.data.pharmacies, totalItems: response.data.total, loading: false })
        } catch (error) {
            if (error.response?.status === 401) {
                window.location.href = LOGIN_PATIENT
            }
            console.log(error);
        }
    }


    toggleFavoritePharmacies = async (id) => {
        try {
            await favoritePharmaciesService.toggleFavoritePharmacies(id)
            this.setState({ FavoritePharmacies: true }, () => this.getFavoritePharmacies())
        } catch (error) {
            console.log(error);
        }
    }


    render() {
        const { favoritePharmacies, loading } = this.state
        return (
            <> {loading && <div className="loader_background">
                <BeatLoader color={"#00c389"} loading={loading} size={20} />
            </div>}
                <div className="FavoritPharmacie_container">
                    <div className="FavoritPharmacie">
                        <Helmet>
                            <meta charSet="utf-8" />
                            <meta name="viewport" content="width=device-width, initial-scale=1" />
                            <title>MonPharmacien - 1er annuaire interactif de pharmacies au Maroc</title>
                            <meta name="description" content="1er annuaire interactif de pharmacies au Maroc. Il permet aux pharmaciens de mettre en ligne leurs tours de garde et aux patients de trouver la pharmacie la plus proche et de communiquer avec leurs pharmaciens." />
                            <meta name="theme-color" content="#00c389" />
                            <meta name="keywords" content="Pharmacie de garde,Pharmacie,Officine" />
                        </Helmet>
                        <Header />
                        <div>
                            <p className="FavoritPharmacie_title">Pharmacies favorites</p>
                            <div className="FavoritPharmacie_form">
                                <p className="FavoritPharmacie_paragraphe">Pharmacie</p>
                                <p className="FavoritPharmacie_paragraphe">Ville</p>
                                <p className="">{' '}</p>
                            </div>
                        </div>
                        {
                            favoritePharmacies.length !== 0 ? favoritePharmacies.map((item, i) => {
                                return (
                                    <div className="pharmacie_favorit_data_container" key={i}>
                                        <p className="FavoritPharmacie_name" onClick={() => this.props.history.push(`/pharmacie/${item.slug}`)}>{item.name}</p>
                                        <p className="FavoritPharmacie_name" onClick={() => this.props.history.push(`/pharmacie/${item.slug}`)}>{item.city && item.city.name}</p>
                                        <AiFillStar className="FavoritPharmacie_icon" onClick={() => this.toggleFavoritePharmacies(item.slug)} />
                                    </div>
                                )
                            }) : <div className="empty_array_container">
                                    <img src={empty_array} className="empty_array" />
                                    <p>Vous n'avez aucune pharmacie en favoris pour le moment.</p>
                                    <button onClick={() => this.props.history.push('/pharmacies')}>Nouvelle recherche</button>
                                </div>
                        }
                    </div>
                </div>
            </>
        );
    }
}

export default FavoritPharmacie;