import React, { Component } from 'react';
import { Button, Input } from 'reactstrap';
import SearchSelect from '../../helpers/AsyncSelect';
import patientsService from '../../services/patients.service';
import Header from '../../helpers/header/header';
import { Helmet } from 'react-helmet';
import BeatLoader from "react-spinners/BeatLoader";
import './ProfileEdit.css';
import { LOGIN_PATIENT } from '../../config';

class ProfileEdit extends Component {
    constructor(props) {
        super(props)
        this.state = {
            authonticatedUser: null,
            fields: {
                ...this.initialFields,
            },
            errors: {},
            cityId: null,
            sectorId: null,
            selectedCity: null,
            loading: true,
            selectedSectors: null
        }
        this.handleChange = this.handleChange.bind(this)
    }

    initialFields = {
        lastName: "",
        firstName: "",
        email: "",
        password: "",
        mobileNumber: "",
        password: "",
        country: "",
        zipcode: null,
    }

    componentDidMount() {
        this.getCurrentPatient()
    }

    handleChange = (input) => (e) => {
        let fieldsCopy = JSON.parse(JSON.stringify(this.state.fields));
        fieldsCopy[input] = e.target.value;
        this.setState({ fields: fieldsCopy, cityId: fieldsCopy.city }, () => {
            if (input === "country") {
                this.setState({ selectedCity: null })
            }
        });
    };

    getCurrentPatient = async () => {
        try {
            let response = await patientsService.getCurrentPatient();
            let prepareData = {
                ...response.data,
                country: response.data?.patientData?.country
            }
            let cityData = {
                value: response.data?.patientData?.city?.id,
                label: response.data?.patientData?.city?.name
            }
            let sectorData = {
                value: response.data?.patientData?.sector?.id,
                label: response.data?.patientData?.sector?.name
            }
            this.setState({
                fields: prepareData,
                selectedCity: cityData,
                selectedSectors: sectorData,
                cityId: response.data?.patientData?.city?.id,
                sectorId: response.data?.patientData?.sector?.id,
                loading: false
            })
        } catch (error) {
            if (error.response?.status === 401) {
                window.location.href = LOGIN_PATIENT
            }
            console.log(error);
        }
    }

    updatePatient = async () => {
        try {
            let fieldsCopy = this.state.fields
            fieldsCopy = {
                city: {
                    id: this.state.cityId
                },
                sector: {
                    id: this.state.sectorId
                },
                country: this.state.fields.country,
                zipcode: this.state.fields.zipcode
            }
            await patientsService.updatePatient(fieldsCopy);
            this.props.history.push('/profile')
        } catch (error) {
            console.error(error);
        }
    }



    render() {
        const { fields, cityId, errors, loading } = this.state;
        return (
            <div className="ProfileEdit">{loading && <div className="loader_background">
                 <BeatLoader color={"#00c389"} loading={loading} size={20} />
            </div>}
                <Helmet>
                    <meta charSet="utf-8" />
                    <meta name="viewport" content="width=device-width, initial-scale=1" />
                    <title>MonPharmacien - 1er annuaire interactif de pharmacies au Maroc</title>
                    <meta name="description" content="1er annuaire interactif de pharmacies au Maroc. Il permet aux pharmaciens de mettre en ligne leurs tours de garde et aux patients de trouver la pharmacie la plus proche et de communiquer avec leurs pharmaciens." />
                    <meta name="theme-color" content="#00c389" />
                    <meta name="keywords" content="Pharmacie de garde,Pharmacie,Officine" />
                </Helmet>
                <Header />
                <div className="PersonnelInfo_form ProfileEdit_Title">
                    <p className="PersonnelInfo_title">Renseignez Vos infomations</p>
                    <p className="PersonnelInfo_paragraphe">Vous pouvez modifier vos informations personnelles à tout moment et en toute simplicité</p>
                </div>
                <div className="ProfileEdit_contect">
                    <div className="form-group">
                        <label for="Nom">Name:</label>
                        <Input type="text" id="Nom" className="search_input_edit border_inside form-control" onChange={this.handleChange("lastName")} value={fields["lastName"]} style={{ backgroundColor: "#e6e6e6" }} disabled />
                        {errors["lastName"] ? <p className="erreurInputText"><><i className="far fa-times-circle"></i> {errors["lastName"]}</></p> : ""}
                    </div>
                    <div className="form-group">
                        <label for="Nom">Prénom:</label>
                        <Input type="text" id="Prénom" className="search_input_edit border_inside" onChange={this.handleChange("firstName")} value={fields["firstName"]} style={{ backgroundColor: "#e6e6e6" }} disabled />
                        {errors["firstName"] ? <p className="erreurInputText"> <><i className="far fa-times-circle"></i> {errors["firstName"]}</></p> : ""}
                    </div>
                    <div className="form-group">
                        <label for='country'>Pays :</label>
                        <select className="search_input_edit border_inside" onChange={this.handleChange("country")} value={fields["country"]} >
                            <option value="ma" >Maroc</option>
                            <option value="dj" >Djibouti</option>
                            <option value="sn" >Sénégal</option>
                        </select>
                    </div>
                    <div className="form-group">
                        <SearchSelect
                            fetchedUrl='/cities'
                            HTTPMethod="getcities"
                            receivedObject="cities"
                            optionLabelProperty="name"
                            optionValueProperty="id"
                            country={fields?.country}
                            value={this.state.selectedCity}
                            placeholder="Ville:"
                            onChange={(e) => {
                                if (e) {
                                    this.setState({ selectedCity: [], cities: [] }, () => {
                                        let city = {
                                            id: e.value
                                        }
                                        this.state.cityId = city;
                                        this.setState({
                                            cityId: e.value,
                                            selectedCity: [...this.state.selectedCity, e],
                                            selectedSectors: null
                                        })
                                    })
                                }
                            }}
                        />
                    </div>
                    {fields.country === "ma" && <div className="form-group">
                        <SearchSelect
                            fetchedUrl='/sectors'
                            HTTPMethod="getsectors"
                            receivedObject="sectors"
                            optionLabelProperty="name"
                            optionValueProperty="id"
                            country={fields?.country}
                            cityId={cityId}
                            value={this.state.selectedSectors}
                            placeholder="Secteur :"
                            onChange={(e) => {
                                if (e) {
                                    this.setState({ selectedSectors: [], sectors: [] }, () => {
                                        let sector = {
                                            id: e.value
                                        }
                                        this.state.sectorId = sector;
                                        this.setState({
                                            sectorId: e.value,
                                            selectedSectors: [...this.state.selectedSectors, e]
                                        })
                                    })
                                }
                            }}
                        />
                    </div>}
                    {fields.country !== "ma" && <div className="form-group">
                        <label for="Nom">Code postal :</label>
                        <Input type="text" id="Nom" className="search_input_edit border_inside form-control" onChange={this.handleChange("zipcode")} value={fields["zipcode"]} />
                    </div>}
                    <Button className="input_button" onClick={() => this.updatePatient()} >Enregistrer</Button>
                    <Button className="cancel_input_button w-100" onClick={() => this.props.history.push('/profile')} >Annuler</Button>
                </div>
            </div>
        );
    }
}

export default ProfileEdit;
