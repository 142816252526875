import React, { Component } from 'react';
import error404 from '../../Image/error404.png';
import Header from '../../helpers/header/header';
import { Button } from 'reactstrap';
import './Error.css';
import { Helmet } from 'react-helmet';

class Error extends Component {
    render() {
        return (
            <div className="Error">
                <Helmet>
                    <meta charSet="utf-8" />
                    <meta name="viewport" content="width=device-width, initial-scale=1" />
                    <title>MonPharmacien - 1er annuaire interactif de pharmacies au Maroc</title>
                    <meta name="description" content="1er annuaire interactif de pharmacies au Maroc. Il permet aux pharmaciens de mettre en ligne leurs tours de garde et aux patients de trouver la pharmacie la plus proche et de communiquer avec leurs pharmaciens." />
                    <meta name="theme-color" content="#00c389" />
                    <meta name="keywords" content="Pharmacie de garde,Pharmacie,Officine" />
                </Helmet>
                <div className="Error_header">
                    <Header />
                </div>
                <div className="Error_components">
                    <img src={error404} className="Error_img" />
                    <p className="Error_title">ERREUR 404</p>
                    <p className="Error_paragraphe">Cette page que vous recherchez n'existe pas</p>
                    <Button className="input_button" onClick={() => this.props.history.push(`/`)}>Retour à l'accueil</Button>
                </div>
            </div>
        );
    }
}

export default Error;