import React, { Component } from 'react';
import { BiBuildings } from 'react-icons/bi';
import { BsChevronRight, BsFillPersonFill } from 'react-icons/bs';
import { withRouter } from 'react-router-dom';
import { IMAGES_BASE_URL } from '../../config';
import empty_image_pharmacieList from '../../Image/empty_image_pharmacieList.png'
import './PharmacieSearched.css';

class PharmacieSearched extends Component {
    constructor(props) {
        super(props);
        this.state = {
            photoDeCover: null
        }
    }

    componentWillMount() {
        this.props.item?.pictures.map(async (item, key) => {
            if (item?.pictureType === "cover") {
                this.setState({ photoDeCover: item })
            }
        })
    }

    componentDidUpdate(prevProps) {
        if (prevProps.item !== this.props.item) {
            console.log("been here");
            if (this.props.item?.pictures?.length !== 0) {
                this.props.item?.pictures.map(async (item, key) => {
                    if (item?.pictureType === "cover") {
                        this.setState({ photoDeCover: item })
                    }
                })
            } else {
                this.setState({ photoDeCover: null })
            }
        }
    }

    render() {
        const { item } = this.props;
        return (
            <div className="pharmacie_card" onClick={() => this.props.history.push(`/pharmacie/${item?.slug}`)}>
                <div className="pharmacie_card_data_container">
                    <div className="image_conatiner_PharmacieSearched">
                        {
                            this.state.photoDeCover ?
                                <img src={IMAGES_BASE_URL + this.state.photoDeCover?.pictureRef} title={this.props.item?.name} className="pharmacy_list_photo" /> :
                                <img src={empty_image_pharmacieList} className="pharmacy_list_photo" />
                        }
                    </div>
                    <div className="pharmacie_card_pharma_info_container">
                        <p className="pharmacie_name">{item.name}</p>
                        {item?.showPharmacistName && <p className="pharmacie_info"><BsFillPersonFill className="pharmacie_icons" />{item?.pharmacist ? (item?.pharmacist?.firstName ?? '') + ' ' + (item?.pharmacist?.lastName ?? '') : "-"}</p>}
                        <p className="pharmacie_info"><BiBuildings className="pharmacie_icons" />{item?.city?.name}</p>
                    </div>
                </div>
                <div>
                    <BsChevronRight className="pharmacie_icons BsChevronRight_icon" />
                </div>
            </div>
        );
    }
}

export default withRouter(PharmacieSearched);
