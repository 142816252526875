import React, { Component } from 'react';
import { AiOutlineClose } from 'react-icons/ai';
import { Button, Input } from 'reactstrap';
import patientsService from '../../services/patients.service';
import './RankingPopup.css';

class RankingPopup extends Component {
    constructor(props) {
        super(props);
        this.state = {
            collapsed: true,
            productAvailabilityScore: null,
            staffCourtesyScore: null,
            pharmacyLayoutScore: null,
            AddrecommendedPharmaciesErrors: false,
            comment: ''
        };
        this.toggleNavbar = this.toggleNavbar.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    toggleNavbar = () => {
        this.setState({
            collapsed: !this.state.collapsed,
        });
    }

    handleChange = (e) => {
        this.setState({ comment: e.target.value })
    }


    handleOptionChange = (changeEvent) => {
        this.setState({
            productAvailabilityScore: changeEvent
        }, () => console.log(this.state.productAvailabilityScore));
    }

    staffHandleOptionChange = (changeEvent) => {
        this.setState({
            staffCourtesyScore: changeEvent
        }, () => console.log(this.state.staffCourtesyScore));
    }

    pharmacyHandleOptionChange = (changeEvent) => {
        this.setState({
            pharmacyLayoutScore: changeEvent
        }, () => console.log(this.state.pharmacyLayoutScore));
    }

    handleRecommendedPharmaciesValidation = async () => {
        const { staffCourtesyScore, productAvailabilityScore, pharmacyLayoutScore } = this.state;
        let formIsValid = true;

        if (pharmacyLayoutScore === null || staffCourtesyScore === null || productAvailabilityScore === null) {
            formIsValid = false;
            this.setState({ AddrecommendedPharmaciesErrors: true });
        }

        return formIsValid;
    };

    AddrecommendedPharmacies = async (slug) => {
        this.setState({ AddrecommendedPharmaciesErrors: false }, async () => {
            let valid = await this.handleRecommendedPharmaciesValidation();
            if (valid) {
                try {
                    let copyRecommendationData = this.state.recommendationData
                    copyRecommendationData = {
                        ...this.state.recommendationData,
                        productAvailabilityScore: this.state.productAvailabilityScore,
                        staffCourtesyScore: this.state.staffCourtesyScore,
                        pharmacyLayoutScore: this.state.pharmacyLayoutScore,
                        comment: this.state.comment,
                        status: true
                    }
                    await patientsService.AddrecommendedPharmacies(slug, copyRecommendationData);
                    this.setState({ collapsed: !this.state.collapsed }, () => {
                        this.props.sayThanksForRecommentdation()
                    })
                } catch (error) {
                    console.log(error);
                }
            }
        });
    }

    render() {
        return (
            <div className={this.state.collapsed ? "BottomPopup" : "BottomPopup_closed"} >
                <div className="BottomPopup-wrapper" onClick={this.toggleNavbar}></div>
                <div className="BottomPopup-nav" style={{ textAlign: "start" }}>
                    <div className='demandePopup_contact'>
                        <p className="BottomPopup_title">Demande de prpriété</p>
                        <AiOutlineClose className="demandePopup_AiOutlineClose" onClick={this.toggleNavbar} />
                    </div>
                    <div className="RankingPopup">
                    {this.state.AddrecommendedPharmaciesErrors && <div className="rating_error">
                        <p>Les notes sont obligatoirement remplis</p>
                    </div>}
                        <div className="ranking_data" >
                            <p className="demande_title">Disponibilité des produits</p>
                            <div className="ranking_buttons">
                                <span className={this.state.productAvailabilityScore === "1" ? "ranking_button ranking_button_active" : 'ranking_button'} onClick={() => this.handleOptionChange("1")} >1</span>
                                <span className={this.state.productAvailabilityScore === "2" ? "ranking_button ranking_button_active" : 'ranking_button'} onClick={() => this.handleOptionChange("2")} >2</span>
                                <span className={this.state.productAvailabilityScore === "3" ? "ranking_button ranking_button_active" : 'ranking_button'} onClick={() => this.handleOptionChange("3")} >3</span>
                                <span className={this.state.productAvailabilityScore === "4" ? "ranking_button ranking_button_active" : 'ranking_button'} onClick={() => this.handleOptionChange("4")} >4</span>
                                <span className={this.state.productAvailabilityScore === "5" ? "ranking_button ranking_button_active" : 'ranking_button'} onClick={() => this.handleOptionChange("5")} >5</span>
                            </div>
                        </div>
                        <div className="ranking_data" >
                            <p className="demande_title">Courtoisie des personnelles</p>
                            <div className="ranking_buttons">
                                <span className={this.state.staffCourtesyScore === "1" ? "ranking_button ranking_button_active" : 'ranking_button'} onClick={() => this.staffHandleOptionChange("1")} >1</span>
                                <span className={this.state.staffCourtesyScore === "2" ? "ranking_button ranking_button_active" : 'ranking_button'} onClick={() => this.staffHandleOptionChange("2")} >2</span>
                                <span className={this.state.staffCourtesyScore === "3" ? "ranking_button ranking_button_active" : 'ranking_button'} onClick={() => this.staffHandleOptionChange("3")} >3</span>
                                <span className={this.state.staffCourtesyScore === "4" ? "ranking_button ranking_button_active" : 'ranking_button'} onClick={() => this.staffHandleOptionChange("4")} >4</span>
                                <span className={this.state.staffCourtesyScore === "5" ? "ranking_button ranking_button_active" : 'ranking_button'} onClick={() => this.staffHandleOptionChange("5")} >5</span>
                            </div>
                        </div>
                        <div className="ranking_data" >
                            <p className="demande_title">Aménagement de la pharmanie</p>
                            <div className="ranking_buttons">
                                <span className={this.state.pharmacyLayoutScore === "1" ? "ranking_button ranking_button_active" : 'ranking_button'} onClick={() => this.pharmacyHandleOptionChange("1")} >1</span>
                                <span className={this.state.pharmacyLayoutScore === "2" ? "ranking_button ranking_button_active" : 'ranking_button'} onClick={() => this.pharmacyHandleOptionChange("2")} >2</span>
                                <span className={this.state.pharmacyLayoutScore === "3" ? "ranking_button ranking_button_active" : 'ranking_button'} onClick={() => this.pharmacyHandleOptionChange("3")} >3</span>
                                <span className={this.state.pharmacyLayoutScore === "4" ? "ranking_button ranking_button_active" : 'ranking_button'} onClick={() => this.pharmacyHandleOptionChange("4")} >4</span>
                                <span className={this.state.pharmacyLayoutScore === "5" ? "ranking_button ranking_button_active" : 'ranking_button'} onClick={() => this.pharmacyHandleOptionChange("5")} >5</span>
                            </div>
                        </div>
                        <Input type="textarea" rows="5" id="exampleEmail" placeholder="Laissez un commentaire" className="input_UpdatePop" value={this.state.comment} onChange={this.handleChange} style={{ width: "100%" }} />
                    </div>
                    <div>
                        <Button className="input_button" onClick={() => this.AddrecommendedPharmacies(this.props.slug)}>Valider</Button>
                        <Button className="UpdatePop_button" onClick={this.toggleNavbar}>Annuler</Button>
                    </div>
                </div>
            </div>
        );
    }
}

export default RankingPopup;
