import React, { Component } from 'react';
import './PharmacieCard.css';
import { Card, CardImg, CardText, CardBody, CardTitle, CardSubtitle } from 'reactstrap';
import photo_empty from '../../Image/photo_empty_forCard.png'
import { withRouter } from 'react-router-dom';
import { FaCity } from 'react-icons/fa';
import { BsFillPersonFill } from 'react-icons/bs';
import { IMAGES_BASE_URL } from '../../config';

class PharmacieCard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            photoDeCover: null
        }
    }

    componentWillMount() {
        this.props.item?.pictures.map(async (item, key) => {
            if (item?.pictureType === "cover") {
                this.setState({ photoDeCover: item })
            }
        })
    }

    componentDidUpdate(prevProps) {
        if (prevProps.item !== this.props.item) {
            console.log("been here");
            if (this.props.item?.pictures?.length !== 0) {
                this.props.item?.pictures.map(async (item, key) => {
                    if (item?.pictureType === "cover") {
                        this.setState({ photoDeCover: item })
                    }
                })
            } else {
                this.setState({ photoDeCover: null })
            }
        }
    }

    render() {
        return (
            <div className="PharmacieCard">
                <Card onClick={() => this.props.history.push(`/pharmacie/${this.props.item.slug}`)} style={{ padding: "8px" }}>
                    {
                        this.state.photoDeCover ?
                            <CardImg to src={IMAGES_BASE_URL + this.state.photoDeCover?.pictureRef} title={this.props.item?.name} className="photo_empty" /> :
                            <CardImg to src={photo_empty} alt="Card image cap" className="photo_empty" />
                    }
                    <CardBody className="CardBody_PharmacieCard">
                        <CardTitle className="card_title" >{this.props && this.props.item?.name}</CardTitle>
                        {this.props.item?.showPharmacistName && <CardSubtitle className="mb-2 text-muted card_paragraphe"><BsFillPersonFill className="icon" />{this.props.item && this.props.item?.pharmacist ? (this.props.item?.pharmacist?.firstName + ' ' + this.props.item?.pharmacist?.lastName) : "-"}</CardSubtitle>}
                        <CardText className="card_paragraphe"><FaCity className="icon" />{this.props && this.props.item.city && this.props.item.city.name}</CardText>
                    </CardBody>
                </Card>
            </div>
        );
    }
}

export default withRouter(PharmacieCard);
