import React, { Component } from 'react';
import photoProfileVide from '../../Image/photoProfileVide.png';
import photo_empty from '../../Image/photo_empty.png';
import Header from '../../helpers/header/header';
import position_icon from '../../Image/position_icon.png'
import { Alert, Button } from 'reactstrap';
import { FaCheck, FaFacebook, FaPhoneAlt } from 'react-icons/fa';
import { ImWhatsapp } from 'react-icons/im';
import favoritePharmaciesService from '../../services/favoritePharmacies.service';
import patientsService from '../../services/patients.service';
import pharmaciesServices from '../../services/pharmacies.services';
// import { MapContainer, Marker, Popup, TileLayer } from 'react-leaflet'
import RankingPopup from '../../helpers/RankingPopup/RankingPopup';
import Marocflags from '../../Image/arabic.png';
import UKflags from '../../Image/english.png';
import spainFlag from '../../Image/espan.png';
import Franceflags from '../../Image/french.png';
import Allemand from '../../Image/Allemand.png'
import Chinois from '../../Image/Chinois.png'
import Japonais from '../../Image/Japonais.png'
import Portugais from '../../Image/Portugais.png'
import Russe from '../../Image/Russe.png'
import Turc from '../../Image/Turc.png'
import Ukrainien from '../../Image/Ukrainien.png'
import Wolof from '../../Image/Wolof.png'
// import Amazigh from '../../Image/Amazigh.png'
import Rifain from '../../Image/Rifain.png'
import ItalyFlag from '../../Image/ItalyFlag.png';
import Cheque from '../../Image/Cheque.png';
import CarteBancaire from '../../Image/CarteBancaire.png';
import Wire_Transfer from '../../Image/Wire Transfer.png';
import Bill_of_Exchange from '../../Image/Bill of Exchange.png';
import Especes from '../../Image/Especes.png';
import Credit from '../../Image/Credit.png';
import Question from '../../Image/question.png'
import Other from '../../Image/Other.png';
import L from 'leaflet'
import moment from 'moment';
import BottomPopup from '../../helpers/BottomPopup/BottomPopup';
import OpeningHours from "../../helpers/openingHours/openingHours"
import { FacebookShareButton, WhatsappShareButton } from "react-share";
import GoogleMapReact from 'google-map-react';
import { HiPlusCircle } from 'react-icons/hi';
import BeatLoader from "react-spinners/BeatLoader";
import Thanks from '../../Image/thanks.png';
import { IoIosThumbsDown, IoIosThumbsUp } from 'react-icons/io';
import './PharmacyProfileVide.css';
import 'leaflet/dist/leaflet.css';
import 'moment/locale/fr';
import { Helmet } from 'react-helmet';
import { IMAGES_BASE_URL, LANDING_PAGE_PHARMACIST } from '../../config';

delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
    //  iconRetinaUrl: require('../../Image/position_icon.png'),
    // iconUrl: position_icon,
    shadowUrl: position_icon
});

const AnyReactComponent = ({ text }) => <HiPlusCircle className="FcPlus_maps_icon" />;

const openingHoursOfnull = [{ id: 1, dayOfWeek: "Lundi", startOne: "09:00", endOne: "12:30", startTwo: "15:00", endTwo: "19:30" },
{ id: 2, dayOfWeek: "Mardi", startOne: "09:00", endOne: "12:30", startTwo: "15:00", endTwo: "19:30" },
{ id: 3, dayOfWeek: "Mercredi", startOne: "09:00", endOne: "12:30", startTwo: "15:00", endTwo: "19:30" },
{ id: 4, dayOfWeek: "Jeudi", startOne: "09:00", endOne: "12:30", startTwo: "15:00", endTwo: "19:30" },
{ id: 5, dayOfWeek: "Vendredi", startOne: "09:00", endOne: "12:30", startTwo: "15:00", endTwo: "19:30" },
{ id: 6, dayOfWeek: "Samedi", startOne: "09:00", endOne: "13:00" }]

class PharmacyProfileVide extends Component {
    constructor(props) {
        super(props);
        this.state = {
            active: 1,
            recommendationData: {
                ...this.recommendationDataFields
            },
            date: new Date(),
            pharmacieData: null,
            show: false,
            FavoritePharmacies: false,
            RankingPopup: false,
            pharmacyId: null,
            position: [30, -6],
            visible: false,
            bottomPopup: false,
            openingHours: [],
            photoDePharmacy: [],
            photoDeCover: null,
            photoDeProfile: null,
            loading: true,
            availablePaymentMethodsPrepared: [],
            openingHoursOpen: [],
            today: null,
            isRecommended: false,
            recommendationDesplay: 0,
            AddrecommendedPharmaciesErrors: false,
            isLoged: null,
            linked: false
        }
    }

    recommendationDataFields = {
        productAvailabilityScore: 0,
        staffCourtesyScore: 0,
        pharmacyLayoutScore: 0,
        status: true
    }

    componentWillMount() {
        const slug = this.props.match && this.props.match.params.slug;
        this.getPharmacieByslug(slug);
        this.getCurrentPatient()
        const today = moment().locale('fr').format('dddd');
        this.setState({ today })
    }

    getCurrentPatient = async () => {
        try {
            let response = await patientsService.getCurrentPatient();
            this.setState({ isLoged: response.data })
        } catch (error) {
            console.log(error);
        }
    }

    toggleRankingPopup = () => {
        this.setState({
            RankingPopup: !this.state.RankingPopup,
        });
    }

    getPharmacieByslug = async (slug) => {
        try {
            let response = await pharmaciesServices.getPharmacieByslug(slug)
            const action = {
                action: "Consultation"
            }
            const filePrincToUpdate = []
            const photoDeCover = []
            const photoDeProfile = []
            response.data.pharmacy?.pictures.map(async (item, key) => {
                if (item?.pictureType === "pharmacy" || item?.pictureType === "cover") {
                    filePrincToUpdate.push(item)
                    this.setState({ photoDePharmacy: filePrincToUpdate })
                }
                if (item?.pictureType === "cover") {
                    photoDeCover.push(item)
                    this.setState({ photoDeCover })
                } else if (item?.pictureType === "pharmacist") {
                    photoDeProfile.push(item)
                    this.setState({ photoDeProfile })
                }

            })
            this.setState({
                pharmacieData: response.data.pharmacy,
                FavoritePharmacies: response.data.favorite,
                pharmacyId: response.data.pharmacy.id,
                openingHours: response.data.pharmacy?.openingHours.length !== 0 ? response.data.pharmacy?.openingHours : openingHoursOfnull,
                loading: false,
                isRecommended: response.data.recommended,
                linked: response.data.linked
            }, () => {
                if (response.data.pharmacy?.openingHours.length !== 0) {
                    let openingHours = null
                    let openingHoursArray = []
                    for (let index = 0; index < this.state.openingHours.length; index++) {
                        openingHours = {
                            dayOfWeek: this.state.openingHours[index]?.dayOfWeek === 0 ? "Lundi" : this.state.openingHours[index]?.dayOfWeek === 1 ? "Mardi" :
                                this.state.openingHours[index]?.dayOfWeek === 2 ? "Mercredi" : this.state.openingHours[index]?.dayOfWeek === 3 ? "Jeudi" :
                                    this.state.openingHours[index]?.dayOfWeek === 4 ? "Vendredi" : this.state.openingHours[index]?.dayOfWeek === 5 ? "Samedi"
                                        : this.state.openingHours[index]?.dayOfWeek === 6 ? "Dimanche" : "",
                            endOne: this.state.openingHours[index]?.endOne,
                            endTwo: this.state.openingHours[index]?.endTwo,
                            id: this.state.openingHours[index]?.id,
                            startOne: this.state.openingHours[index]?.startOne,
                            startTwo: this.state.openingHours[index]?.startTwo
                        }
                        openingHoursArray.push(openingHours)
                    }
                    this.preparOpeningHoursData(openingHoursArray)
                } else {
                    this.preparOpeningHoursData(this.state.openingHours)
                }
                this.ActionOfClientInPharmacie(this.state.pharmacieData.slug, action)
                if (this.state.pharmacieData?.gpsLocation) {
                    const position = this.state.pharmacieData?.gpsLocation.split(' ')
                    let positionNet = []
                    positionNet = position.map(element => {
                        return parseFloat(element)
                    });
                    this.setState({ position: [...positionNet] })
                }
                if (this.state.pharmacieData?.availablePaymentMethods !== 0) {
                    if (this.state.pharmacieData?.availablePaymentMethods?.some((el) => el?.name === "other")) {
                        let availablePaymentMethodsPrepared = [];
                        let availablePaymentMethodsPreparedrest = [];
                        let lastOne = []
                        this.state.pharmacieData?.availablePaymentMethods?.map((item) => {
                            if (item?.name !== "other") {
                                availablePaymentMethodsPreparedrest?.push(item)
                            } else {
                                lastOne.push(item)
                            }
                        })
                        availablePaymentMethodsPrepared = availablePaymentMethodsPreparedrest.concat(lastOne)
                        this.setState({ availablePaymentMethodsPrepared })
                    } else {
                        this.setState({ availablePaymentMethodsPrepared: this.state.pharmacieData?.availablePaymentMethods })
                    }
                }
            }
            )
        } catch (error) {
            if (404 === error.response.status) {
                this.props.history.push('/error')
            }
            console.log(error);
        }
    }


    ActionOfClientInPharmacie = async (slug, Action) => {
        try {
            await patientsService.clientAddActionToPharmacies(slug, Action)
        } catch (error) {
            console.log(error);
        }
    }

    toggleFavoritePharmacies = async (id) => {
        try {
            await favoritePharmaciesService.toggleFavoritePharmacies(id)
            this.setState({ FavoritePharmacies: true })
        } catch (error) {
            console.log(error);
        }
    }

    preparOpeningHoursData(openingHours) {
        let pos = openingHours.findIndex((el) => el.dayOfWeek === (this.state.today.charAt(0).toUpperCase() + this.state.today.slice(1)));
        let openingHoursClose = [];
        let openingHoursOpen = [];

        for (let index = pos; index < openingHours.length; index++) {
            openingHoursClose.push(openingHours[index])
        }

        for (let index = 0; index < pos; index++) {
            openingHoursOpen.push(openingHours[index])
        }

        const array3 = openingHoursClose.concat(openingHoursOpen);

        this.setState({ openingHoursOpen: array3 })
    }

    sayThanksForRecommentdation = () => {
        this.setState({ recommendationDesplay: 2 })
    }

    render() {
        const { pharmacieData, position, bottomPopup, linked, photoDeCover, photoDeProfile, recommendationDesplay, photoDePharmacy, loading, availablePaymentMethodsPrepared, openingHoursOpen, isRecommended, isLoged } = this.state
        return (
            <>{loading && <div className="loader_background">
                <BeatLoader color={"#00c389"} loading={loading} size={20} />
            </div>}
                <Helmet>
                    <meta charSet="utf-8" />
                    <meta name="viewport" content="width=device-width, initial-scale=1" />
                    <title>MonPharmacien - 1er annuaire interactif de pharmacies au Maroc</title>
                    <meta name="description" content={`Cette page représente ${pharmacieData && pharmacieData?.name} qui se trouve à ${pharmacieData?.city ? pharmacieData?.city?.name : ''} (${pharmacieData?.sector ? pharmacieData?.sector?.name : ''}),
                     à l’adresse ${pharmacieData && pharmacieData.address}. Cette pharmacie est gérée par Dr. ${pharmacieData?.pharmacist ? (pharmacieData.pharmacist?.firstName + " " + pharmacieData.pharmacist?.lastName) : ''}`} />
                    <meta name="theme-color" content="#00c389" />
                    <meta name="keywords" content={` Pharmacie de garde, Pharmacie à ${pharmacieData?.city ? pharmacieData?.city?.name : ''}, Pharmacie à ${pharmacieData?.sector ? pharmacieData?.sector?.name : ''}, 
                    ${pharmacieData && pharmacieData.name}, ${pharmacieData?.pharmacist ? (pharmacieData.pharmacist?.firstName + " " + pharmacieData.pharmacist?.lastName) : ''}`} />
                </Helmet>
                <div className="PharmacyProfileVide_header" >
                    <Header />
                </div>
                <div className="PharmacyProfileVide">
                    <div>
                        <div className="photo_cover_container">
                            {
                                photoDeCover ?
                                    <img src={IMAGES_BASE_URL + photoDeCover[0]?.pictureRef} title={pharmacieData && pharmacieData?.name} className="photo_de_covertureVide" /> :
                                    <img src={photo_empty} className="photo_de_covertureVide" />
                            }
                        </div>
                        <div className={photoDeProfile ? "photo_de_profile" : "photo_de_ProfileVide"}>
                            {
                                photoDeCover ?
                                    <img src={IMAGES_BASE_URL + photoDeCover[0]?.pictureRef} /> :
                                    <img src={photoProfileVide} />
                            }
                        </div>
                    </div>

                    <div style={{ paddingLeft: "16px", paddingRight: '16px' }}>
                        <div className="PharmacyProfileVide_contect" >
                            <p className="nom_de_pharmacie">{pharmacieData && pharmacieData.name}</p>
                            <p className="pharmacie_paragraphe">{pharmacieData?.pharmacist ? pharmacieData?.pharmacist?.firstName + ' ' + pharmacieData?.pharmacist?.lastName : ''}</p>
                            <p className="pharmacie_paragraphe">{pharmacieData && (pharmacieData.pharmacist?.degreeSchool || pharmacieData?.pharmacist?.degreeYear) ? ((pharmacieData.pharmacist?.degreeSchool ?? '') + ', ' + (pharmacieData?.pharmacist?.degreeYear ?? '')) : ""}</p>
                            <div style={{ position: "relative" }}>
                                <p className="fabrique_title">Informations</p>
                            </div>
                            {
                                !linked && <a className="question_form" onClick={() => window.open(LANDING_PAGE_PHARMACIST)}>
                                    <div>
                                        <p>c'est votre pharmacie?</p>
                                        <p>Devenez proprietaire de cette page</p>
                                    </div>
                                    <div>
                                        <img src={Question} className="Question_img" />
                                    </div>
                                </a>}
                            {bottomPopup && <BottomPopup />}
                            <div className="pharma_general_data">
                                <p className="profile_vide_data_title">Téléphone</p>
                                <div className="profile_vide_data_popup_opner">
                                    {pharmacieData?.phone ?
                                        <p className="pharma_info_contect">{pharmacieData?.phone}</p> :
                                        <p className="pharma_info_contect">-</p>}
                                </div>
                                <p className="profile_vide_data_title">Langues parlées à la pharmacie</p>
                                <div className="profile_vide_data_popup_opner spokenLanguages_container">
                                    {pharmacieData && pharmacieData.spokenLanguages.length !== 0 ?
                                        pharmacieData?.spokenLanguages.map((item, key) => {
                                            return <div className="availableServices_container" style={{ width: "33%" }}>
                                                <img style={{ maxWidth: "21px" }} src={item?.name === "Arabe" ? Marocflags : item?.name === "Allemand" ? Allemand : item?.name === "Russe" ? Russe
                                                    : item?.name === "Français" ? Franceflags : item?.name === "Anglais" ? UKflags : item?.name === "Espagnol" ? spainFlag
                                                        : item?.name === "Italien" ? ItalyFlag : item?.name === "Ukrainien" ? Ukrainien : item?.name === "Portugais" ? Portugais
                                                            : item?.name === "Chinois" ? Chinois : item?.name === "Japonais" ? Japonais : item?.name === "Turc" ? Turc
                                                                : item?.name === "Wolof (Sénégal)" ? Wolof : item?.name === "Amazigh" ? Rifain : item?.name === "Rifain" ? Rifain : ''} />
                                                <p key={key} className="pharma_info_contect" style={{ margin: "5px" }}>
                                                    {item?.name}
                                                </p>
                                            </div>
                                        }) :
                                        <div className="availableServices_container">
                                            < p className="pharma_info_contect">-</p>
                                        </div>
                                    }
                                </div>
                                <p className="profile_vide_data_title">Services de la pharmacie</p>
                                <div className="profile_vide_data_popup_opner" >
                                    {pharmacieData?.availableServices.length !== 0 ?
                                        pharmacieData?.availableServices.map((item, key) => {
                                            return <div className="availableServices_container">
                                                <FaCheck className="availableServices_icon" />
                                                <p key={key} className="pharma_info_contect" style={{ margin: "5px" }}>{item?.name}</p>
                                            </div>
                                        }) :
                                        <div className="availableServices_container">
                                            < p className="pharma_info_contect">-</p>
                                        </div>
                                    }
                                </div>
                                <p className="profile_vide_data_title" style={{ marginBottom: '5px' }}>Horaires d'ouverture de la pharmacie</p>
                                {
                                    openingHoursOpen.length !== 0 ? <OpeningHours openingHours={openingHoursOpen} /> : <div className="profile_vide_data_popup_opner">
                                        <p className="pharma_info_contect">-</p>
                                    </div>
                                }
                                <p className="profile_vide_data_title">Moyens de paiement acceptés</p>
                                <div className="profile_vide_data_popup_opner availablePaymentMethods_container">
                                    {availablePaymentMethodsPrepared.length !== 0 ?
                                        availablePaymentMethodsPrepared.map((item, key) => {
                                            return <div className="availableServices_container ">
                                                {item?.name === "cash" ?
                                                    <div className="PaiementPopup" >
                                                        <img src={Especes} />
                                                        <p className="PaiementPopup_title">Espèces</p>
                                                    </div> :
                                                    item?.name === "check" ?
                                                        <div className="PaiementPopup" >
                                                            <img src={Cheque} />
                                                            <p className="PaiementPopup_title">Carte fidélité</p>
                                                        </div> :
                                                        item?.name === "debit_card" ?
                                                            <div className="PaiementPopup" >
                                                                <img src={CarteBancaire} />
                                                                <p className="PaiementPopup_title">Carte Bancaire</p>
                                                            </div> : item?.name === "bill_of_exchange" ?
                                                                <div className="PaiementPopup" >
                                                                    <img src={Bill_of_Exchange} />
                                                                    <p className="PaiementPopup_title">Lettre de change</p>
                                                                </div> : item?.name === "credit" ?
                                                                    <div className="PaiementPopup" >
                                                                        <img src={Credit} />
                                                                        <p className="PaiementPopup_title">Credit</p>
                                                                    </div> : item?.name === "other" ?
                                                                        <div className="PaiementPopup" >
                                                                            <img src={Other} />
                                                                            <p className="PaiementPopup_title">Autre</p>
                                                                        </div> : item?.name === "wire_transfer" ?
                                                                            <div className="PaiementPopup" >
                                                                                <img src={Wire_Transfer} />
                                                                                <p className="PaiementPopup_title">Virement bancaire</p>
                                                                            </div> : ""
                                                }
                                            </div>
                                        }) :
                                        <div className="availableServices_container">
                                            < p className="pharma_info_contect">-</p>
                                        </div>
                                    }
                                </div>
                                <p className="pharma_info_title">Dernière modification:</p>
                                <div className="profile_vide_data_popup_opner">
                                    {pharmacieData?.updatedAt ?
                                        <p className="pharma_info_contect">{moment(pharmacieData?.updatedAt).format("DD/MM/YYYY")}</p> :
                                        <p className="pharma_info_contect">-</p>}
                                </div>
                            </div>
                        </div>

                        <div className="pharma_general_data">
                            <p className="pharma_info_title">Adresse</p>
                            <div className="profile_vide_data_popup_opner">
                                {pharmacieData && pharmacieData.address ? <p className="pharma_info_contect"> {pharmacieData?.address}  </p> : <p className="pharma_info_contect">-</p>}
                            </div>
                            <p className="pharma_info_title">Ville</p>
                            <div className="profile_vide_data_popup_opner">
                                {pharmacieData && pharmacieData.city ? <p className="pharma_info_contect"> {pharmacieData?.city?.name}  </p> : <p className="pharma_info_contect">-</p>}
                            </div>
                            <p className="profile_vide_data_title">Secteur</p>
                            <div className="profile_vide_data_popup_opner">
                                {
                                    pharmacieData && pharmacieData.sector ?
                                        <p className="pharma_info_contect">{pharmacieData?.sector?.name}</p> :
                                        <p className="pharma_info_contect">-</p>
                                }
                            </div>
                            {
                                pharmacieData?.gpsLocation && <>
                                    <p className="profile_vide_data_title">Localisation</p>
                                    <div className="profile_vide_data_popup_opner">
                                        <div className="MapComponent">
                                            <GoogleMapReact
                                                style={{ height: "200px" }}
                                                bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_MAP_KEY }}
                                                center={{
                                                    lat: position[0],
                                                    lng: position[1]
                                                }}
                                                zoom={13}
                                                onClick={() => {
                                                    const action = {
                                                        action: "Itinerary"
                                                    }
                                                    this.ActionOfClientInPharmacie(pharmacieData?.slug, action)
                                                }}
                                            >
                                                <AnyReactComponent
                                                    lat={position[0]}
                                                    lng={position[1]}
                                                    text=""
                                                />
                                            </GoogleMapReact>
                                        </div>

                                    </div>
                                </>
                            }
                        </div>

                        <div className="pharma_general_data">
                            <div className="profile_vide_data_popup_opner">
                                <p className="profile_vide_data_title">Photos</p>
                                <div className="photoDePharmacy_container">
                                    {
                                        photoDePharmacy.length !== 0 ? photoDePharmacy.map((photo, key) => {
                                            return <div className="photoDePharmacy" key={key}>
                                                <img src={IMAGES_BASE_URL + photo?.pictureRef} className="photoDePharmacy_image" />
                                            </div>
                                        }) : <div>
                                            <p className="profile_vide_data_title">-</p>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>

                        {(!isRecommended && isLoged) && <>
                            <div className="mb-2">
                                {recommendationDesplay === 0 && <div className="pharma_general_data">
                                    <p className="pharma_info_title">Vous recommandez cette pharmacie ?</p>
                                    <div className="IoIosThumbs_bottons">
                                        <Button className="IoIosThumbsUp_button" onClick={this.toggleRankingPopup}><IoIosThumbsUp /></Button>
                                        <Button className="IoIosThumbsDown_button" onClick={() => this.setState({ recommendationDesplay: 2 })}><IoIosThumbsDown /></Button>
                                    </div>
                                </div>
                                }
                                {recommendationDesplay === 2 &&
                                    <div className="pharma_general_data" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                        <h2 style={{ marginRight: "10px" }}>Merci</h2>
                                        <img src={Thanks} alt="monpharmacien.ma RecomendationThanks" className="RecomendationThanksImage " />
                                    </div>}
                            </div>
                        </>}
                        {this.state.RankingPopup && <RankingPopup RankingPopup={this.state.RankingPopup} slug={pharmacieData?.slug} sayThanksForRecommentdation={this.sayThanksForRecommentdation} />}
                        <div className="pharma_general_data social_media">
                            <p className="pharma_info_title">Partager sur</p>
                            <div>
                                <FacebookShareButton url={window.location.href} >
                                    <FaFacebook className="social_media_icons facebook_icon" />
                                </FacebookShareButton>
                                <WhatsappShareButton url={window.location.href}>
                                    <ImWhatsapp className="social_media_icons whatsapp_icon" />
                                </WhatsappShareButton>
                            </div>
                        </div>
                        {pharmacieData?.phone ?
                            <div className="call_button_position">
                                <Button className="profile_vide_input_button" onClick={() => {
                                    window.open(`tel:${pharmacieData?.phone}`)
                                    const action = {
                                        action: "Call"
                                    }
                                    this.ActionOfClientInPharmacie(pharmacieData?.slug, action)
                                }}><FaPhoneAlt className="profile_vide_input_button_icon" />Appeler la pharmacie</Button>
                            </div> : ""
                        }
                    </div>
                </div>
            </>
        );
    }
}

export default PharmacyProfileVide;
